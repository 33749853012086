@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    box-sizing: border-box;
    width: 100%;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-thumb {
    background-color: #495670;
    border: 3px solid #0a192f;
    border-radius: 10px;
}